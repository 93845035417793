
/*
https://css-tricks.com/a-handy-little-system-for-animated-entrances-in-css/
*/

/* ==========================================================================
Loading Animation System by Neale Van Fleet from Rogue Amoeba
========================================================================== */
.animate {
  animation-duration: 0.75s;
  animation-duration: 1s; //running slower to show effect
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(.26,.53,.74,1.48);
  animation-fill-mode: backwards;
}

/* Fade In */
.animate.fade {
  animation-name: animate-fade;
  animation-timing-function: ease;
}
@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Pop In */
.animate.pop {
  animation-name: animate-pop;
}
@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

/* Blur In */
.animate.blur {
  animation-name: animate-blur;
  animation-timing-function: ease;
}
@keyframes animate-blur {
  0% {
    opacity: 0;
    filter: blur(15px);
  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

/* Grow In */
.animate.grow {
  animation-name: animate-grow;
}
@keyframes animate-grow {
  0% {
    opacity: 0;
    transform: scale(1, 0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

/* Splat In */
.animate.splat {
animation-name: animate-splat;
}
@keyframes animate-splat {
0% {
opacity: 0;
transform: scale(0, 0) rotate(20deg) translate(0, -30px);
}
70% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(15deg);
}

85% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(15deg) translate(0, -10px);
}

100% {
    opacity: 1;
    transform: scale(1, 1) rotate(0) translate(0, 0);
}
}

/* Roll In */
.animate.roll {
animation-name: animate-roll;
}
@keyframes animate-roll {
0% {
opacity: 0;
transform: scale(0, 0) rotate(360deg);
}
100% {
    opacity: 1;
    transform: scale(1, 1) rotate(0deg);
}
}

/* Flip In */
.animate.flip {
animation-name: animate-flip;
transform-style: preserve-3d;
perspective: 1000px;
}
@keyframes animate-flip {
0% {
opacity: 0;
transform: rotateX(-120deg) scale(0.9,0.9);
}
100% {
    opacity: 1;
    transform: rotateX(0deg) scale(1,1);
}
}

/* Spin In */
.animate.spin {
animation-name: animate-spin;
transform-style: preserve-3d;
perspective: 1000px;
}
@keyframes animate-spin {
0% {
opacity: 0;
transform: rotateY(-120deg) scale(0.9,0.9);
}
100% {
    opacity: 1;
    transform: rotateY(0deg) scale(1,1);
}
}

/* Slide In */
.animate.slide { animation-name: animate-slide; }
@keyframes animate-slide {
0% {
opacity: 0;
transform: translate(0,20px);
}
100% {
    opacity: 1;
    transform: translate(0,0);
}
}

/* Drop In */
.animate.drop { 
animation-name: animate-drop; 
animation-timing-function: cubic-bezier(.77,.14,.91,1.25);
}
@keyframes animate-drop {
0% {
opacity: 0;
transform: translate(0,-300px) scale(0.9, 1.1);
}
95% {
    opacity: 1;
    transform: translate(0,0) scale(0.9, 1.1);
}
96% {
    opacity: 1;
    transform: translate(10px,0) scale(1.2, 0.9);
}
97% {
    opacity: 1;
    transform: translate(-10px,0) scale(1.2, 0.9);
}
98% {
    opacity: 1;
    transform: translate(5px,0) scale(1.1, 0.9);
}
99% {
    opacity: 1;
    transform: translate(-5px,0) scale(1.1, 0.9);
}
100% {
    opacity: 1;
    transform: translate(0,0) scale(1, 1);
}
}

/* Animation Delays */
.delay-1 {
animation-delay: 0.6s;
}
.delay-2 {
animation-delay: 0.7s;
}
.delay-3 {
animation-delay: 0.8s;
}
.delay-4 {
animation-delay: 0.9s;
}
.delay-5 {
animation-delay: 1s;
}
.delay-6 {
animation-delay: 1.1s;
}
.delay-7 {
animation-delay: 1.2s;
}
.delay-8 {
animation-delay: 1.3s;
}
.delay-9 {
animation-delay: 1.4s;
}
.delay-10 {
animation-delay: 1.5s;
}
.delay-11 {
animation-delay: 1.6s;
}
.delay-12 {
animation-delay: 1.7s;
}
.delay-13 {
animation-delay: 1.8s;
}
.delay-14 {
animation-delay: 1.9s;
}
.delay-15 {
animation-delay: 2s;
}
.delay-3s {
animation-delay: 3s;
}
.delay-35s {
animation-delay: 3.5s;
}
.delay-4s {
animation-delay: 4s;
}
.delay-45s {
animation-delay: 4.5s;
}

.delay-3s1 {
animation-delay: 3s;
}
.delay-3s2 {
animation-delay: 3.3s;
}
.delay-3s3 {
animation-delay: 3.6s;
}


@media screen and (prefers-reduced-motion: reduce) {
  .animate {
    animation: none !important;
  }
}