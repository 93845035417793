@use "nfjs-variable";

@use "admin-links";
@use "main-menu";

@use "../../../2019/nfjs/component/nfjs-deprecate";

@import url('https://fonts.googleapis.com/css?family=Open+Sans|Lato|Roboto');


body {
  margin: 0; padding: 0;
  background: #000;
  font-family: 'Open-Sans', Helvetica, Sans-Serif;
}

h1, h2, h3, h4, h5 { font-weight: normal; margin: 0; }

a { color: nfjs-variable.$orange; }

div.body-wrapper {
  margin: 0 auto;
  background: #000;
}

div#top-bar div.menu-wrapper ul.top-links li.training-event-2 a {
  color: nfjs-variable.$orange;
}


.clearfix:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  width: 0;
  visibility: hidden;
}

.hidden {
  display: none !important;
}

.strike { text-decoration: line-through; }

.clickable:hover {
  cursor: pointer;
}

.orange-button {
  border: 0;
  background: nfjs-variable.$orange;
  color: #fff;
  padding: 5px 25px;
  display: inline-block;
  border-radius: 4px;
}

.orange-button:hover {
  color: yellow;
  text-decoration: none;
}

.orange-button.disabled {
  background: #999;
  margin-bottom: 10px;
}

.orange-button.disabled:hover {
  cursor: pointer;
}


ul.plain {
  list-style: none; margin-left: 0; padding-left: 0;
  li { clear: left; margin: 3px 0 0 0; padding: 0; }
}

footer {
  padding: 60px 0;
  text-align: center;
  background: #000000;
  color: #fff;

  a { color: #fff; }
  a:hover { color: nfjs-variable.$orange; }
  ul { list-style: none; text-align: left; }

  svg.nfjs-logo { height:100px; margin-bottom: 30px; }
  img.nfjs-logo { height:100px; margin-bottom: 30px; }


}

.speaker-circle-md img {
  border-radius: 70px;
}

.speaker-circle-sm img {
  border-radius: 29px;
}

#modal-alert {
  display: none;

  position: fixed;

  bottom: 30px;
  right: 30px;
  width: 400px;
  z-index: 1000;


  background: #fff;
  color: #000;
  border: 2px solid rgba(255, 0, 0, 1);
  border-radius: 8px;

  div.modal-content {
    background: rgba(255, 0, 0, 0.2);
    padding: 10px;
    font-size: 14px;
  }

  i { position: absolute; top: 5px; right: 5px; }
  i:hover { cursor: pointer; }

  h3 { color: #cc0000; text-align: center; }
  a { color: #cc0000; }
}

@media only screen and (max-width: 600px) {

  #modal-alert {
    width: 300px;
    right: 10px;
  }

}

div#password-expired {
  text-align: center;
  background: rgba(255, 0, 0, 0.2);
  a { color: #cc0000; text-decoration: underline; }
}



