
@use "nfjs-variable";

div#contact-form {
  color: #000;
  background: #fff;
  border: 2px solid nfjs-variable.$orange;
  border-radius: 4px;
  padding: 15px;
  max-width: 360px;

  div.success-message {
    color: #cc0000;
    margin-bottom: 40px;
  }

  h4 { font-size: 28px; }

  input[type='email'] {
    display: block;
    width: 100%;
    font-size: 18px;
    margin-bottom: 10px;
  }

  button.orange-button {
    color: #000;
  }

}