@use "nfjs-variable";



div#announce {
  padding: 10px;
  background: linear-gradient(270deg, #f5ea26, nfjs-variable.$orange );
  color: #000;
  text-align: center;
  a { color: #000; text-decoration: underline; }
  a:last-child { color: #000; font-weight: bold;  }
}

div#top-bar {
  width: 100%;
  padding: 10px 0 5px 0;

  //position: relative;
  //position: fixed;
  //right: 0;
  //left: 0;
  z-index: 1;
  background: #000;

  svg.nfjs-logo { height:60px; }
  img.nfjs-logo { height:60px; }

  a.home-link {
    font-family: 'Roboto', 'Open Sans', Sans-Serif;
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    span { color: nfjs-variable.$orange; }
  }
  a.home-link:hover {
    text-decoration: none;
  }


  div.menu-wrapper {
    display: inline-block;
    float: right;
    text-align: right;
    max-height: 60px;

    ul.top-links {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 0;
      padding: 0;
      font-size: 18px;
      list-style: none;
      li {
        display: inline;
        a { color: #fff; padding: 5px 10px; }
      }
      li.training-event {
        a { padding: 0px 10px; }
        a:hover { text-decoration: underline; }
        div.training-event-wrapper {
          max-width: 150px;
          display: inline-block;
          text-align: center;
          div.small { font-size: 12px; line-height: 14px; }
          div.large { font-size: 18px; line-height: 18px; }
        }
      }


    }

    button {
      display: inline-block;
      background: 0;
      width: 40px;
      height: 40px;
      border: 0;
      padding: 0;

      svg { width: 100%; }
      img { width: 100%; }
    }
    button:focus { outline: 0; }


    #nfjs-drop-down {
      display: none;
      min-width: 320px;
      position: relative;
      top: -1000px;
      right: 0;

      z-index: 2;
      background: #fff;
      //border: 1px solid #fff;
      border: 1px solid #999;
      color: darkblue;
      text-align: left;
      font-size: 22px;

      ul {
        margin: 0; padding: 0;
        li {
          list-style: none;
          margin: 0; padding: 0;
          border-bottom: 1px solid #999;
          a {
            color: #000;
            padding: 5px 30px;
            display: block;
            margin: 0;
          }
          a:hover { color: nfjs-variable.$orange; }

          a#nfjs-menu-close {
            position: absolute;
            width: 25px; height: 25px;
            display: block;
            top: 0;
            right: 0;
            z-index: 2;
            svg, img { width: 25px; height: 25px; }
          }
          a#nfjs-menu-close:hover {
            cursor: pointer;
          }

        }
        li.mobile { display: none; }
        li.register a { font-weight: bold;text-shadow: 1px 1px yellow; }

        li.divider {
          border-bottom: 1px solid #999;
          font-weight: bold;
          padding: 10px 30px;
          color: nfjs-variable.$orange;
        }
        li:last-child {
          border-bottom: 0;
        }

      }

    }

    #nfjs-drop-down.show {
      display: block;
      transition-timing-function: ease-in;
      transition: 0.9s;
      top: -64px;
    }



    @media only screen and (max-width: 1200px) {
      ul.top-links {
        font-size: 16px;
        //line-height: 18px;
      }
    }


    @media only screen and (max-width: 992px) {

      ul.top-links { display: none; }
      #nfjs-drop-down {
        ul>li.mobile { display: block; }
      }
    }

    @media only screen and (max-width: 500px) {
      #nfjs-drop-down {
        position: absolute;
        //top: 0;
        //left: 0;
        width: 100%;
      }
      #nfjs-drop-down.show {
        top: 0;
      }


    }


  }


}

div#top-bar.sticky {
  background: #000;
  //height: 55px;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1001
}
