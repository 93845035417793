$dark-green: #153744;
$dark-blue: #143B4A;
$orange: #F7941E;
$dark-orange: #E95B2A;

$blue: #175E8C;
$light-blue: #3B92FC;


$mobile-size: 578px;

$cdn_nfjs: 'https://cdn.nofluffjuststuff.com';

