.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }
.mb50 { margin-bottom: 50px; }
.mb100 { margin-bottom: 100px; }
.mb200 { margin-bottom: 200px; }
.mb300 { margin-bottom: 300px; }

.w20  { width: 20px; }
.w30  { width: 30px; }
.w40  { width: 40px; }
.w50  { width: 50px; }
.w60  { width: 60px; }
.w70  { width: 70px; }
.w80  { width: 80px; }
.w100 { width: 100px; }
.w120 { width: 120px; }
.w125 { width: 125px; }
.w130 { width: 130px; }
.w140 { width: 140px; }
.w150 { width: 150px; }
.w160 { width: 160px; }
.w180 { width: 180px; }
.w200 { width: 200px; }
.w220 { width: 220px; }
.w230 { width: 230px; }
.w240 { width: 240px; }
.w250 { width: 250px; }
.w275 { width: 275px; }
.w300 { width: 300px; }
.w350 { width: 350px; }
.w375 { width: 375px; }
.w400 { width: 400px; }
.w480 { width: 480px; }
.w500 { width: 500px; }
.w550 { width: 550px; }
.w600 { width: 600px; }
.w650 { width: 650px; }
.w700 { width: 700px; }
.w750 { width: 750px; }
.w800 { width: 800px; }
.w900 { width: 900px; }