
@use "../bootstrap/bootstrap-full";

@use "component/nfjs-variable" as nfjs-variable;

@use "component/nfjs-site";
@use "component/animate";
@use "component/contact-form";

@use "component/topic-list-animate";


// DEVOPS font
@import url('https://fonts.cdnfonts.com/css/ethnocentric');


body {
  font-family: 'Open-Sans', Helvetica, Sans-Serif;
}

h1, h2, h3, h4, h5 { font-weight: normal; margin: 0; }
h4, h5 { font-size: 16px; }

div.body-wrapper {
  background: #000000;
  color: #fff;
}

div.layer {
  padding: 40px 0;
  h3 {
    font-size: 32px;
    font-family: 'Open Sans', Sans-Serif;
    margin-bottom: 10px;
  }
}

div.nfjs-main-page {
  position: relative;

  div.intro-carousel {

    .carousel-indicators {
      justify-content: right;
      margin-right: 20px;

      li {
        text-indent: 0;
        background: none;
        height: 30px;
        i { color: nfjs-variable.$orange; }
        i.fas { display: none; }
        i.far { display: inline-block; }
      }

      li.active {
        i.fas { display: inline-block; }
        i.far { display: none; }
      }
    }

    div.item {
      min-height: 500px;
    }

    div.item.uber {
      text-align: center;
      img {
        width: 100%;
        max-width: 600px;
      }
      h3.tagline {
        font-family: Robot, 'Open Sans', Sans-serif;
        font-size: 16px;
        margin-bottom: 20px;
      }
      h3.date-location { margin-bottom: 20px; }

      h4 { color: nfjs-variable.$orange; }
      h4.save { margin-bottom: 30px; font-size: 20px; }

    }

    div.item.webinar {

      //border: 2px solid nfjs-variable.$orange;
      width: 100%;
      height: 100%;
      display: table;

      text-align: center;
      padding: 25px;

      div.webinar-cell {
        display: table-cell;
        vertical-align: middle;
      }

      h4.free { color: nfjs-variable.$orange; font-size: 28px; }
      h3 {
        color: yellow;
        margin: 0 0 30px 0;
        font-size: 32px;
      }

      div.image {
        text-align: right;
        padding-right: 10px;
      }

      div.speaker-circle-md {
        display: inline-block;
        img {
          border: 2px solid nfjs-variable.$orange;
        }
      }

      div.about {
        text-align: left;
        h4 { font-size: 28px; margin-bottom: 10px; }
      }

    }

    div.item.three60 {
      padding: 30px 0;
      border: 1px solid nfjs-variable.$orange;

      div.row>div {
        padding: 0 30px;
      }

      div.image {
        min-height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;

         img { width: 500px; max-width: 100%; }
      }
      div.about {
        display: flex;
        align-items: center;

        div.about-stuff {
          font-size: 20px;
          line-height: 28px;

          ul {
            list-style: none;
            margin: 0 0 20px 0;
            padding: 0 0 0 0;
            li {
              margin-bottom: 8px;
            }
          }
          div.button-wrapper {
            text-align: center;
          }
        }


      }

    }

    div.item.arch {
      border: 1px solid nfjs-variable.$orange;
      border-radius: 4px;
      background: url(nfjs-variable.$cdn_nfjs + '/styles/2019/arch/intro_bg_1500-2.jpg');
      text-align: center;

      img {
        width: 250px;
        max-width: 100%;
      }
      h3 {
        font-family: "Montserrat",Helvetica,Arial,sans-serif;
        font-size: 48px;
        color: #fff;
        span { color: nfjs-variable.$orange; }
      }
      h4 { font-size: 22px; }
      h3, h4 { margin-bottom: 10px; }
      h5 { color: nfjs-variable.$orange; }
    }

    div.item.mlops {
      border: 1px solid nfjs-variable.$orange;
      border-radius: 4px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      div.image-title {
        display: table;
        margin: 0 auto 20px auto;
        //border: 1px solid nfjs-variable.$orange;

        a { display: table-cell; }

        a.image {
          text-align: right;
          vertical-align: middle;
          padding-right: 10px;
          img { max-width: 100%; }
        }

        a.title {
          text-align: left;
          vertical-align: middle;

          h3 {
            color: rgba(255, 255, 255, 0);
            font-family: 'Open-Sans', Helvetica, Sans-Serif;
            font-size: 78px;
            line-height: 78px;
            -webkit-text-stroke-width: 3px;
            -webkit-text-stroke-color: nfjs-variable.$orange;
          }
        }
        a.title:hover {
          text-decoration: none;
        }
      } /* image-title */

      h4 { font-size: 22px; }
      h3, h4 { margin-bottom: 10px; }
      h5 { color: nfjs-variable.$orange; }

      @media (max-width: 768px) {

        div.image-title {
          a.image img { max-width: 100px; }
          a.title h3 { font-size: 48px; line-height: 48px; }
        }
      }

      @media (max-width: 400px) {

        div.image-title {
          a.image img { max-width: 80px; }
          a.title h3 { font-size: 36px; line-height: 36px; -webkit-text-stroke-width: 2px; }
        }
        h4 { font-size: 16px; }
        h5 { font-size: 14px; }
      }

    }



    div.item.training {
      text-align: center;
      border: 1px solid nfjs-variable.$orange;

      div.row {
        padding: 20px 0 0 0;
      }

      div.training-event {
        padding: 10px;
        max-width: 800px;

        min-height: 320px;
        margin: 0 auto 0 auto;


        h3 {
          font-size: 32px;
          margin-bottom: 10px;
          color: nfjs-variable.$orange;
        }

        h4.two-day { color: yellow; font-size: 24px; }

        h4.date-location {
          font-size: 24px;
        }
        h4.speaker-name {
          font-size: 18px;
        }



         div.speaker-circle-md {
           margin-bottom: 5px;
           img { border: 1px solid nfjs-variable.$orange; }
         }
      }

      div.training-event.copilot {
        h3 { font-size: 22px; }
      }


    } /* training */


    div.item.tls {
      background: url(nfjs-variable.$cdn_nfjs + '/styles/2019/cto/cto_bg_1500.jpg');
      border: 1px solid nfjs-variable.$orange;
      border-radius: 4px;
      text-align: center;
      padding-top: 80px;

      a.title {
        text-decoration: none;
      }

      h3 {
        font-size: 100px;
        line-height: 100px;
        color: #4c6aef;
        span {
          color: #fff;
        }
      }
      h4 { font-size: 32px; margin-bottom: 10px; }
      h5 { color: nfjs-variable.$orange; }

    }

    @media (max-width: 991px) {
      div.item.tls {
        h3 { font-size: 64px; line-height: 64px; }
        h4 { font-size: 24px; }
      }
    }

    div.item.devops {
      //background: url(nfjs-variable.$cdn_nfjs + '/styles/2023/devops/devops_bg_1500.jpg');
      background: #343e3d;
      border: 1px solid nfjs-variable.$orange;
      border-radius: 4px;
      text-align: center;


      div.item-wrapper {
        background: linear-gradient(200deg, rgba(255,255,255,.2), rgba(255,255,255,0) 40%);
        height: 100%;
        padding-top: 40px;
      }

      img {
        //max-width: 175px;
        margin-bottom: 20px;
      }

      a.title {
        text-decoration: none;
      }

      h3 {
        font-family: Ethnocentric, Helvetica, Sans-Serif;
        font-size: 88px;
        line-height: 88px;
        color: #fff;
      }
      h4 { font-size: 32px; color:  #E86D1E; margin-bottom: 10px; }
      h5 { color: #fff; }

    } // devops main

    @media (max-width: 1200px) {
       div.item.devops {
         h3 { font-size: 68px; line-height: 68px; }
       }
    }

    @media (max-width: 991px) {
      div.item.devops {
        div.item-wrapper {
          padding-top: 20px;
        }
        //h3 { font-size: 74px; line-height: 74px; }
        h4 { font-size: 24px; }
      }
    }

    @media (max-width: 768px) {
      div.item.devops {
        h3 { font-size: 64px; line-height: 64px; }
        h4 { font-size: 24px; }
      }
    }

    @media (max-width: 500px) {
      div.item.devops {
        h3 { font-size: 48px; line-height: 48px; }
        h4 { font-size: 18px; }
      }
    }

    // end devops

    @media (max-width: 768px) {

      div.main-intro {
        div.right-side {
          iframe {
            max-width: 400px;
            max-height: 300px;
          }
        }
        p#tagline {
          font-size: 16px;
          margin-bottom: 30px;
        }
      }

      div.item.training {
        div.training-event {
          padding-top: 20px;
          h3 { font-size: 18px; }
          h4 { font-size: 16px;}
        }
        div.training-event.copilot {
          h3 { font-size: 18px; }
        }
      } /* training */

      div.item.tls {
        h3 { font-size: 48px; line-height: 48px; }
        h4 { font-size: 18px; }
      }


    } /* intro-carousel 768 */

    @media (max-width: 575px) {

      div.item.uber {
        h3.date-location { font-size: 18px; }
      }

      div.item.training {
        div.training-event {
          margin: 0 auto 30px auto;
        }
        div.training-event.copilot {
          margin: 0 auto 70px auto;
        }
      }

      div.item.tls {
        h3 { font-size: 32px; line-height: 32px; }
      }

      div.item.arch {
        img {
          width: 200px;
        }
        h3 { font-size: 24px; }
        h4 { font-size: 18px; }
      }


    }


  } /* intro-carousel */

  div.main-intro {
    padding: 60px 0 40px 0;

    img.logo {
      max-width: 177px;
      margin-bottom: 10px;
    }

    h1 {
      color: rgba(255, 255, 255, 0);
      font-family: 'Open-Sans', Helvetica, Sans-Serif;
      font-size: 78px;
      line-height: 78px;
      -webkit-text-stroke-width: 3px;
      -webkit-text-stroke-color: nfjs-variable.$orange;
    }

    p.tagline {
      font-size: 18px;
    }

    div.right-side {
      padding-bottom: 30px;
      iframe {
        width: 100%;
        border: 1px solid nfjs-variable.$orange;
        min-height: 315px;
        border-radius: 8px;
      }
    }

    p#tagline {
      //border: 1px solid #999;
      text-align: center;
      font-size: 22px;
      margin: 0;
    }

    @media only screen and (max-width: 1199px) {
      h1 { font-size: 64px; line-height: 64px; }
    }

    @media only screen and (max-width: 1000px) {
      img.logo { max-width: 125px; }
      h1 { font-size: 48px; line-height: 48px; }
    }

    @media only screen and (max-width: 768px) {
      h1 { margin-bottom: 30px; font-size: 64px; line-height: 64px; }
    }

    @media only screen and (max-width: 500px) {
      h1 { font-size: 48px; line-height: 48px; }
    }


  } // main-intro



  div.features {

    a.feature {
      display: block;
      height: 178px;
      max-width: 488px;
      border: 2px solid nfjs-variable.$orange;
      padding: 15px;
      margin: 0 auto 10px auto;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      h4 { color: #fff; }
      .save { color: #cc0000; }

      div.button-wrapper {
        text-align: center;
        .orange-button {
          border-radius: 4px;
        }
      }

      .speaker-circle-sm {
        img { border: 2px solid nfjs-variable.$orange; }
      }

    }
    a.feature:hover {
      text-decoration: none;
      box-shadow: 0 0 3px 3px #ffffff;
    }

    a.feature.uber {
      //padding-top: 20px;
      img { width: 100%; }
      h5.tag {
        font-size: 14px;
        color: nfjs-variable.$orange;
        margin-bottom: 20px;
      }
      h4.dates { margin: 0 0 8px 0;}
      h4.save { margin: 0 0 20px 0;}

    } // uber

    a.feature.arch {
      //padding-top: 20px;
      h3 {
        color: #fff;
        font-family: Roboto, 'Open Sans', Arial, Sans-Serif;
        font-weight: 700;
        margin: 0 0 5px 0;
        span { color: #E86D1E; }
      }
      h4.dates { margin-bottom: 10px; }
      h4.save { margin-bottom: 20px; color: yellow; }
      h5.tag {
        font-size: 14px;
        color: nfjs-variable.$orange;
        margin-bottom: 20px;
      }
    } // arch

    a.feature.mlops {

      h4 {
        font-size: 16px;
        margin-bottom: 10px;
      }
      h4.save {
        margin-bottom: 20px;
      }
    }

    a.feature.tls {
      background-color: rgba(0,0,0,0);
      background-image: url(nfjs-variable.$cdn_nfjs + '/app-images/2024/nfjs/destination/tls_calendar_bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      h3 { font-size: 28px; color: #4C6AEF; span { color: #fff; } }

      h4 {
        font-size: 16px;
        margin-bottom: 10px;
      }
      h4.save {
        margin-bottom: 20px;
        color: yellow;
      }
      h5.tag { font-size: 14px; margin-bottom: 20px; }

    } // tls

    a.feature.webinar {
      div.info-wrapper {
        margin-bottom: 15px;
      }
    } // webinar

    a.feature.training {
      h3 { margin-bottom: 5px; }
      div.info-wrapper {
        margin-bottom: 10px;
      }
    } // training

    a.feature.webinar, a.feature.training {
      //background: #1B6290;
      text-align: left;

      h3 { color: #FFFF00; font-size: 20px; }
      h3.long { font-size: 14px; }
      h4 { font-size: 18px; }
      h5 { font-size: 16px; color: #FFFF00; }

      div.info-wrapper {
        display: table;
      }
      div.speaker-circle-sm, div.about {
        display: table-cell;
      }


      div.speaker-circle-sm {
        width: 80px;
        vertical-align: top;
      }
      div.about {
        max-width: 300px;
      }

    }

  } // features

div.calendar {

  h3 {
    text-align: center;
    font-family: 'Open Sans', Sans-Serif;
    font-size: 28px;
    margin-bottom: 10px;
  }
  @media (max-width: 1000px) {
    h3 { font-size: 18px; }
  }

  a.item, div.empty {
    padding: 5px 10px 5px 10px;
    height: 85px;
    overflow: hidden;
    display: block;
    //background: #fff;
    background: url('https://cdn.nofluffjuststuff.com/styles/2022/nfjs/card_4.jpg');
    border: 1px solid nfjs-variable.$orange;
    border-radius: 4px;
    margin-bottom: 20px;
    color: #fff;

    div.info-wrapper {
      display: table;
      width: 100%;
      height: 100%;
    }
    div.speaker-circle-sm, div.about {
      display: table-cell;
      img {
        border: 2px solid nfjs-variable.$orange;
      }
    }
    div.speaker-circle-sm {
      width: 63px;
      vertical-align: middle;
    }
    div.about {
      vertical-align: middle;
    }

    @media (max-width: 1000px) {
      div.speaker-circle-sm { display: none; }
    }
    @media (max-width: 767px) {
      div.speaker-circle-sm { display: table-cell; }
    }

  } // item

  a.item:hover {
    background: #ff9;
    box-shadow: 0 0 3px 3px #ffffff;
    text-decoration: none;
    h4, h5 { color: #000; }
  }

  a.item.show {
    //padding: 10px 15px 10px 15px;
    h4 { font-size: 20px; }
    h4.long { font-size: 18px; }
    h5 { font-size: 18px; color: nfjs-variable.$orange; margin-left: 15px; }
    @media (max-width: 1200px) {
      h4, h5, h4.long { font-size: 16px; }
    }
  }

  // uber
  a.item.show.app-10 {
    background: #000;
    background-image: none;
    h4 { color: #fff; }
    //color: nfjs-variable.$orange;
    img { max-width: 100%; border: none; }

  }

  // tls
  a.item.show.app-19 {
    background-color: rgba(0,0,0,0);
    background-image: url(nfjs-variable.$cdn_nfjs + '/app-images/2024/nfjs/destination/tls_calendar_bg.jpg');
    h4 {
      color: #4C6AEF;
      font-size: 24px;
      span { color: #fff; }
    }
  }

  // archconf
  a.item.show.app-13 {

    //background: #013B5E;
    background: #000;

    h4 {
      color: #fff;
      font-size: 24px;
      img {
        border: none;
        max-height: 30px;
        margin-right: 5px;
        margin-top: -10px;
      }
    }
  }

  // devops
  a.item.show.app-23 {
    //background-color: rgba(0,0,0,0);
    background-image: url(nfjs-variable.$cdn_nfjs + '/app-images/2024/nfjs/destination/devops_calendar_bg.jpg');
    background-size: cover;

    h4 {
      //margin-left: 60px;
      //margin-bottom: 10px;
      color: #fff;
      font-size: 24px;
      span { color: #fff; }
      img {
        border: none;
        max-height: 30px;
        margin-right: 5px;
        margin-top: -10px;
      }
    }
  }

  // mlops
  a.item.show.app-25 {
    background-color: rgba(0,0,0,0);
    //background-image: url(nfjs-variable.$cdn_nfjs + '/app-images/2024/nfjs/destination/devops_calendar_bg.jpg');
    background-image: none;
    //background-size: cover;

    h4.title { display: none; }

    img { border: none; }
    h5 { color: #fff; }

    h4 {
      //margin-left: 60px;
      //margin-bottom: 10px;
      color: #fff;
      font-size: 24px;
      span { color: #fff; }
      img {
        border: none;
        max-height: 30px;
        margin-right: 5px;
        margin-top: -10px;
      }
    }
  }

  a.item.show.app-26 {
    background: linear-gradient(180deg, rgba(3, 9, 25,1) 0%, rgba(25,63,104,1) 100%);
    img { max-width: 250px; border: 0; margin: 0 0 5px 10px; }
    h4.title { display: none; }
    h5 { color: #fff; }
  }

  a.item.workshop {

    h4 { font-size: 16px; line-height: 20px; }
    h4.long { font-size: 15px; line-height: 18px; }
    h5 { margin-left: 15px; font-size: 16px; line-height: 20px; }
    h5.date { color: nfjs-variable.$orange;  }

  }

  a.item.webinar {
    //padding: 5px 10px 5px 10px;

    h4 { font-size: 16px; line-height: 20px; }
    h4.long { font-size: 15px; line-height: 18px; }
    h5 { margin-left: 15px; font-size: 16px; line-height: 20px; }
    h5.date { color: nfjs-variable.$orange;  }
    h5.date.current { color: #CC0000;  }
  }
  a.item.webinar.current {
    background: #ff9;
    h4, h5 { color: #000; }
  }

  a.more {
    color: #fff;
    float: right;
  }
  a.more:hover {
    color: #ffff00;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    div.empty { display:  none; }
  }

} /* calendar */

div.world-class-and-training {

  text-align: center;

  div.world-class {
    padding-bottom: 30px;
    text-align: left;
  }

  a.item.training {
    display: block;
    margin: 0 auto 10px auto;
    padding: 5px 10px 5px 10px;
    background: #fff;
    border: 2px solid nfjs-variable.$orange;
    border-radius: 4px;
    max-width: 440px;
    text-align: left;
    color: #000;

    div.info-wrapper {
      display: table;
      div.speaker-circle-sm, div.about {
        display: table-cell;
        vertical-align: middle;
        img {
          border: 2px solid nfjs-variable.$orange;
        }
      }
      div.about {
        padding-left: 10px;
        h5 { padding-left: 15px; }
        h5.date { color: nfjs-variable.$blue; }
      }
    }

  }
  a.item.training:hover {
    background: #ff9;
    text-decoration: none;
  }

}  /* video-and-training */

div.future-blurb {

  div.images {
    //padding-top: 40px;

    div.col-6, div.col-12 {
      padding: 5px;
      img {
        max-width: 100%;
        border-radius: 4px;
        border: 2px solid nfjs-variable.$orange;
      }
    }
  }

}

div.subscribe-topics {

  div.subscribe-side {
    padding-top: 60px;
  }


} /* subscribe-topics */

div.virtual-workshops {

  p {
    max-width: 900px;
  }

  h4 { font-size: 22px; }
  ul {
    list-style: none;
    margin: 0; padding: 0;
    i { color: nfjs-variable.$orange; margin-right: 10px;font-size: 20px; }
  }

  div.button-column {
    padding-top: 100px;
    a {
      display: block;
      min-width: 165px;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  iframe {
    max-width: 100%;
  }

  @media only screen and (max-width: 991px) {
      div.button-column {
        padding-top: 20px;
        padding-bottom: 20px;
      }
  }

} /* virtual-workshops */

div.destination-events {

  div.row > div {
    text-align: center;
  }

  a.image {
    margin-bottom: 10px;
    display: inline-block;
  }
  a {
    color: #fff;
    img {
      border-radius: 4px;
      border: 1px solid nfjs-variable.$orange;
      max-width: 100%;
    }
  }
  a:hover {
    color: #ffff00;
    text-decoration: none;
  }
  h4 { font-size: 18px; }
  h5 { font-size: 16px; }

  @media (max-width: 768px) {
    h5 { font-size: 14px; }
  }

  @media (max-width: 575px) {
      h5 { margin-bottom: 20px; }
  }

} /* destination-events */

div.past-events {
  background: #fff;
  color: #000;

  a.show {
    display: table;
    width: 100%;
    padding: 3px;
    margin: 0 0 15px 0;

    h4, h5 { color: #000; }


    div.image {
      display: table-cell;
      width: 100px;
      img { width: 100%; border-radius: 3px;
        border: 1px solid nfjs-variable.$orange; }

    }
    div.about {
      padding-left: 10px;
      vertical-align: top;
      display: table-cell;
      color: nfjs-variable.$blue;
      h4 { font-size: 18px; }
      h5 { font-size: 16px; }
    }
  }
  a.show:hover {
    text-decoration: none;
    background: #ff9;
  }

  a.more {
    float: right;
  }

}

div.partners-expect {
  background: #f1f1f1;
  color: #000;

  div.partners-apps {
    text-align: center;
  }

  h4 { font-size: 28px; margin-bottom: 20px; }

  a {
    display: inline-block;
    img {  }
  }

}




} /* nfjs-main-page */



