
@keyframes slide-in {
  from {
    transform: translateY(20px);
    opacity: 0;
    font-size: 32px;
    line-height: 24px;
  }
  to {
    transform: translateY(0);
    opacity: 1;
    font-size: 16px;
  }
}


div.topics {

  li {
    animation: slide-in 1s ease-in-out;
    animation-play-state: paused; /* Initially paused */
  }

  li.in-view {
    animation-play-state: running;
  }

}