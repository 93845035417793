div.admin-links {
  position: relative;
  z-index: 2;
  background: #f1f1f1; color: #333;
  max-height: 40px;
  //overflow: hidden;


  .navbar-default {
    background: 0;
    .navbar-nav>li>a { color: #333; padding: 5px 10px; }
    .navbar-nav>li>a:hover { color: #EF8F1C;  }
  }

  ul.rolenav {
      margin: 0;
      list-style: none;
      li {
        display: inline-block;
        a {
          padding: 8px 5px 8px 5px;
        }
        a#find-user-link {
          i { color: #333; }
        }
        a#find-user-link:hover {
          i { color: yellow; }
        }

        a.system-alert {
          background: rgba(255, 0, 0, 0.2);
          border: 1px solid #cc0000;
          border-radius: 4px;
          padding: 3px;
          color: #cc0000;
          font-weight: bold;
        }

        a.feedback-alert {
          background: rgba(54, 168, 232, 0.2);
          border: 1px solid #004BCCFF;
          border-radius: 4px;
          padding: 3px;
          color: #004bcc;
          font-weight: bold;
          margin-left: 10px;
        }
    }
  }

  @media only screen and (max-width: 500px) {

    ul.rolenav {
       li.role-label { display: none; }
    }

  }

}